<template>
  <div class="v-my-prefer">
    <base-layout>
      <n-form :label-width="80" :model="form" :rules="rules" ref="formRef" label-placement="top">
        <n-grid :cols="12" :x-gap="20">
          <n-form-item-gi :span="gridColSetting.password" label="新密碼" path="password">
            <div class="input-remind-wrap">
              <n-input placeholder="Input" v-model:value="form.password" />
              <p class="remind-text">8-20 characters</p>
            </div>
          </n-form-item-gi>
          <n-form-item-gi :span="gridColSetting.confirmPassword" label="確認新密碼" path="confirmPassword">
            <div class="input-remind-wrap">
              <n-input placeholder="Input" v-model:value="form.confirmPassword" />
              <p class="remind-text">8-20 characters</p>
            </div>
          </n-form-item-gi>
        </n-grid>
      </n-form>

      <div class="btn-wrap">
        <mi-button>Save</mi-button>
      </div>
    </base-layout>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue';
import {
  NForm, NFormItemGi, NGrid, NInput,
} from 'naive-ui';
import MiButton from '@/components/Global/MiButton.vue';
import BaseLayout from '@/components/MyProfile/BaseLayout.vue';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'ChangedPassword',
  components: {
    MiButton,
    BaseLayout,
    NForm,
    NFormItemGi,
    NGrid,
    NInput,
  },
  setup() {
    const siteStore = useSiteStore();

    const gridColSetting = computed(() => {
      if (siteStore.deviceType.includes('tablet')) {
        return {
          password: 6,
          confirmPassword: 6,
        };
      }

      return {
        password: 12,
        confirmPassword: 12,
      };
    });

    return {
      gridColSetting,
      form: ref({
        password: '',
        confirmPassword: '',
      }),
      rules: {
        password: {
          required: true,
          trigger: ['input'],
        },
        confirmPassword: {
          required: true,
          trigger: ['input'],
        },
      },
    };
  },
});
</script>
<style lang="scss" scoped>
.btn-wrap {
  @include flex(center);
  margin-top: 65px;
}

.input-remind-wrap {
  width: 100%;
}
.remind-text {
  @include font-style($c-assist3, 12, normal, 0.24px, 24px);
}

@media screen and (min-width: $tablet) {
  .btn-wrap {
    @include flex(flex-end);
    margin-top: 65px;
  }
}
</style>
